













































































































































































































import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase";
import axios from "@/axios";
@Component
export default class Login extends Vue {
  clearText = false;
  errors = [];
  email = "";
  password = "";

  isActiveUser = true;

  checkIfIsGuest(email, type) {
    const guestKey = localStorage.getItem("guestKey");
    const hotelKey = localStorage.getItem("hotelKey");
    const gk = guestKey ? guestKey : 0;
    const hk = hotelKey ? hotelKey : 0;
    const Url = `${process.env.VUE_APP_APIURL}/checkguest/${hk}/${gk}`;
    const data = { e: email ? btoa(email) : null };
    axios
      .post(Url, data)
      .then(response => {
        if (response.data) {
          if (type === "formRegister")
            this.createUserRedirect(response.data, guestKey);
          if (type === "formLogin")
            this.UserLoginRedirect(response.data, guestKey);
        }
      })
      .catch(e => {
        this.errors.push(e);
      });
  }

  formRegister(e) {
    this.errors = [];
    e.preventDefault();
    const okay = this.checkForm();
    const type = "formRegister";
    if (okay) {
      this.checkIfIsGuest(this.email, type);
    }
  }

  // redirectPage(guestKey, callbackGuestKey) {
  //   if (callbackGuestKey) {
  //     this.$router.push(`/guest/${callbackGuestKey}`);
  //   } else {
  //     if (guestKey) localStorage.removeItem("guestKey");
  //     this.$router.push("/guest");
  //   }
  // }

  redirectPage(guestKey, callbackData) {
    if (callbackData.guestKey) {
      this.$router.push(`/guest/${callbackData.guestKey}`);
    } else {
      const hotelInfo = this.$store.state.hotelInfo;
      if (hotelInfo) {
        if (guestKey) localStorage.removeItem("guestKey");
        this.$router.push("/guest");
      }
      if (!hotelInfo) {
        this.$router.push(`/guest/${guestKey}`);
      }
    }
  }

  createUserRedirect(data, guestKey) {
    if (data.error) this.errors.push(data.error);
    if (data.info) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(userCredential => {
          this.$store.commit("setAuthProfile", userCredential.user);
          this.redirectPage(guestKey, data.info);
        })
        .catch(error => {
          let message = error.message;
          if (error.code == "auth/user-not-found") {
            this.isActiveUser = false;
            message = "There is no email set up. Please register";
          }
          this.errors.push(error.code);
          this.errors.push(message);
        });
      return null;
    }
  }

  UserLoginRedirect(data, guestKey) {
    if (data.error) this.errors.push(data.error);
    if (data.info) {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(userCredential => {
          console.log(userCredential);
          this.$store.commit("setAuthProfile", userCredential.user);
          this.redirectPage(guestKey, data.info);
        })
        .catch(error => {
          let message = error.message;
          if (error.code == "auth/user-not-found") {
            this.isActiveUser = false;
            message = "There is no email set up. Please register";
          }
          this.errors.push(error.code);
          this.errors.push(message);
        });
    }
  }

  formLogin(e) {
    this.errors = [];
    e.preventDefault();
    const okay = this.checkForm();
    const type = "formLogin";
    if (okay) {
      this.checkIfIsGuest(this.email, type);
    }
    // if (okay) {

    //   firebase
    //     .auth()
    //     .signInWithEmailAndPassword(this.email, this.password)
    //     .then(userCredential => {
    //       console.log(userCredential);
    //       this.$store.commit("setAuthProfile", userCredential.user);
    //       const guestKey = localStorage.getItem("guestKey");
    //       this.redirectPage(guestKey, false);
    //     })
    //     .catch(error => {
    //       let message = error.message;
    //       if (error.code == "auth/user-not-found") {
    //         this.isActiveUser = false;
    //         message = "There is no email set up. Please register";
    //       }
    //       this.errors.push(error.code);
    //       this.errors.push(message);
    //     });
    //   return null;
    // }
  }

  get activeUser() {
    return this.isActiveUser;
  }

  get hotelData() {
    return this.$store.state.hotelInfo;
  }

  setUser(status) {
    this.isActiveUser = status;
    this.errors = [];
  }

  checkForm() {
    if (this.email && this.password) {
      return true;
    }
    this.errors = [];
    if (!this.email) {
      this.errors.push("Username / Email required");
    }
    if (!this.password) {
      this.errors.push("Password required");
    }
  }

  socialLogin(social) {
    let provider;
    if (social === "Google") {
      provider = new firebase.auth.GoogleAuthProvider();
    }
    if (social === "Facebook") {
      provider = new firebase.auth.FacebookAuthProvider();
    }
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        this.$store.commit("setAuthProfile", result.user);
        const guestKey = localStorage.getItem("guestKey");
        this.redirectPage(guestKey, false);
      })
      .catch(err => {
        alert("Oops. " + err.message);
      });
  }
}
